
export default {
    name: 'Exchange',
    layout: 'main-head',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            biz: {
                worksUrl: '',
                process: [],
                price: []
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getRecyclePriceAjax()
    },
    methods: {
        getRecyclePriceAjax() {
            this.$api.mixins.getRecyclePrice().then(response => {
                const res = response.result || [];
                this.biz.price = res
            })
        },
        handleConsult() {
            try {
                // ECHAT.customMiniChat()
                Reamaze.popup();
            } catch {}
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('YFN Jewelry Exchange'),
                keywords: this.$translate('YFN Jewelry Exchange'),
                description: this.$translate('We want to embed sustainability into everything we do. We use our own production advantages to buy back jewelry produced by YFN to ensure a healthy "life cycle".'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1703841521062.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            const lang = this.$storage.get('language') || {};
            const worksUrlObj = {
                'en_US': 'https://cdn2.selleroa.com/yfn-upload/review/1703835318245.png',
                'fr_FR': 'https://cdn2.selleroa.com/yfn-upload/review/1703835308339.png',
                'de_DE': 'https://cdn2.selleroa.com/yfn-upload/review/1703835304267.png',
                'es_ES': 'https://cdn2.selleroa.com/yfn-upload/review/1703835310943.png',
                'pt_PT': 'https://cdn2.selleroa.com/yfn-upload/review/1703835315543.png',
            };
            this.biz.worksUrl = worksUrlObj[lang.identify] || worksUrlObj['en_US']
            this.biz.process = [{
                text: this.$translate(`Application`),
            }, {
                text: this.$translate(`Valuate the Price`),
            }, {
                text: this.$translate(`Agree & Upload Tracking Number`),
            }, {
                text: this.$translate(`Unbox & Confirm`),
            }, {
                text: this.$translate(`Weigh & Account Price`),
            }, {
                text: this.$translate(`Send Offer`),
            }, {
                text: this.$translate(`Sign the Agreement`),
            }, {
                text: this.$translate(`Get Paid`),
                desc: `(${this.$translate(`Extra 10% off coupon`)})`,
            }]
        }
    },
}
