
import IndexSliderImg from './IndexSliderImg';

export default {
    name: 'IndexSlider',
    props: {
        data: Object,
        info: Object
    },
    components: {
        IndexSliderImg
    },
    data() {
        return {
            rsInfo: [],
            bgUrl(obj){
                return obj?.bg?.url||''
            },
            bgHeight(obj){
                return obj?.bg?.bgHeight?obj?.bg?.bgHeight+'px':'auto'
            },
            getHtml(item){
                return item.html || ''
            }
        }
    },
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            const info = JSON.parse(JSON.stringify(this.info))
            this.rsInfo = info.slideData
            // console.log(this.rsInfo)
        },
    }
}
