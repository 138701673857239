
export default {
    name: 'IndexImage',
    props: {
        data: Object,
        info: Object
    },
    data() {
        return {
            rsInfo: {},
            list: []
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 设置数据
        setData() {
            // sizeType -> 1 自定义 2 自适应
            const info = JSON.parse(JSON.stringify(this.info))
            this.rsInfo = info
            this.rsInfo.rs = {}
            this.list = this.rsInfo.picList || []
            this.rsInfo.rs.visible = this.list.length
            this.rsInfo.rs.hasOnlyOne = this.list.length < 2
            this.rsInfo.rs.ref = `swiper${this.rsInfo.i}`
            // 设置图片数据
            this.setImgOptions()
            // swiper数据
            this.setSwiperOptions()
        },
        // 设置图片数据
        setImgOptions() {
            let length = this.list.length
            this.list.forEach((item, i) => {
                // item.lazyLoad = true
                item.lazyLoad = false
                if((!i || i == length - 1) && length > 1) {
                    item.lazyLoad = false
                }
            })
        },
        // 设置swiper数据
        setSwiperOptions() {
            let rs = this.rsInfo.rs;
            rs.options = {}
            rs.slideStyle = {}
            rs.blockStyle = {}
            rs.modelStyle = {}
            // style
            // if(this.rsInfo.sizeType == 1) {
            //     const unit = 'px'
            //     const w = this.rsInfo.picWidth + unit
            //     const h = this.rsInfo.picHeight + unit
            //     const mr = this.rsInfo.picInterval + unit;
            //     const pn = this.rsInfo.picMargins + unit
            //     const bc = this.rsInfo.backgroundColor
            //     const al = this.rsInfo.picAlign || ' flex-start'
            //     rs.radius = this.rsInfo.picFillet + unit
            //     rs.options.slidesPerView = 'auto'
            //     rs.slideStyle = {
            //         width: w,
            //         height: h,
            //     }
            //     rs.blockStyle = {
            //         'margin-right': mr
            //     }
            //     rs.modelStyle = {
            //         padding: `0 ${pn}`,
            //         'box-sizing': 'border-box',
            //         'background-color': bc
            //     }
            //     this.rsInfo.rs.hasOnlyOne && (rs.modelStyle['justify-content'] = al)
            // }
            // 切换
            const first = this.list[0];
            const hasFirstVideo = first.videoUrl;
            rs.options.loop = !this.rsInfo.rs.hasOnlyOne
            hasFirstVideo && (rs.options.loop = false)
            rs.options.watchOverflow = this.rsInfo.rs.hasOnlyOne
            if(this.rsInfo.automaticCarousel && !hasFirstVideo) {
                rs.options.autoplay = {
                    delay: this.rsInfo.carouselInterval * 1000 || 3000,
                    stopOnLastSlide: true,
                    disableOnInteraction: false
                }
            }
            // 分页
            if(!rs.hasOnlyOne) {
                if(this.rsInfo.pagination) {
                    rs.options.pagination = {
                        el: '.swiper-pagination',
                        clickable: true,
                    }
                }
            }
        },
        jumpLink(obj) {
            obj.jumpUrl && (window.location.href = obj.jumpUrl)
        },
    }
}
